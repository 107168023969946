body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", sans-serif;
}

* {
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --mainColor: #1b1925;
  --mainColorLight: #312f3b;
  --mainColorLight2: #4b4957;
  --homePageColorPage1: #4b4957;
  --homePageColorPage2: #4b4957;
  --homePageColorPage3: #4b4957;
  --homePageColorPage4: #4b4957;
  --secondaryColor: #3bacb6;
  --thirdColor: #d82b39;
  --whiteColor: #fff;
  --blackColor: #000;
  --textColor: #eee;
  --textColor: #eee;
}
