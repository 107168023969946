body {
  background-color: var(--mainColorLight); 
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--secondaryColor);
  text-align: center;
  letter-spacing: 7px !important;
}

h2 {
  font-size: 3rem;
  font-weight: 700;
  color: white;
  text-align: center;
  letter-spacing: 4px;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
  color: white;
  text-align: center;
}

h4 {
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  text-align: center;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1.0rem;
  }
}
