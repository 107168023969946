.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
}
.overlay:target {
    visibility: visible;
    opacity: 1;
}

.wrapper {
    width: 500px;
      margin: 70px auto;
      padding: 40px;
      background: rgba(221, 218, 255, 0.3);
      border-radius: 15px;
      position: relative;
    backdrop-filter: blur(10px);
  }
  .wrapper h2 {
    font-size: 25px;
    text-align: center;
      margin-top: 0px;
    margin-bottom: 30px;
      color: #fff;
  }
  .wrapper .close {
      position: absolute;
      top: 20px;
      right: 30px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: #2b1055;
  }
  .cancel{
    text-decoration: none;
      color: #676280;
  }
  .wrapper .close:hover {
      color: #d80606;
  }
  .wrapper .content {
      max-height: 30%;
      overflow: auto;
  }
  /*form*/
  
  form{
    height: 340px;
    color: #fff;
  }
  
  label {
    color: #fff;
    font-family:Arial, Helvetica, sans-serif;
      font-weight: 500;
      letter-spacing: 1px;
    margin-bottom: -10px;
  }
  
  input{
    height: 40px;
    background: rgba(152, 149, 194, 0.3);
    border: none;
    outline: none;
    color: white;
  }
  input::placeholder {
    opacity: 0.7;
    color: #e7e7e7;
  }
  textarea::placeholder {
    opacity: 0.7S;
    color: #e7e7e7;
  }
  
  input.telephone{
    margin-top: 16px;
  }
  
  .textareabit {
    position: relative;
    margin-top: 14px;
  }
  .handle-hide {
    height:12px;
    width:12px;
    position: absolute;background:#686682;
    bottom: 21px;
    right: 2px;
    pointer-events: none;
    z-index: 200000;
  }
  textarea{
    color: #fff;
    height: 105px;
    background: #686682;
    border: none;
    outline: none;
  }
  
  .btn-field{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
  }
  
  .btn-field button{
    background: #2aabb5;
    color: #fff;
    height: 50px;
    border-radius: 10px;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-size: 20px;
    width: 100%;

  }
  .button-div{
    flex-basis: 48%;
    background: #2aabb5;
    color: #fff;
    height: 50px;
    border-radius: 10px;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-size: 20px;
    align-items: center;
    align-content: center;
  }
  .btn-field button:hover {
      color: #ffffff;
    background: #4fced7;
  }
  
  .btn-field button.disable{
    background: #332f44;
    color: #4d476b;
  }
  .btn-field button.disable:hover {
      color: #ffffff;
    background: #5645a2;
  }
  
  input[type=text], select, textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid #ccb9cc;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
  }

@media (max-width: 768px) {
  .wrapper {
    width: 90%;
  }
  .wrapper .close {
    font-size: 20px;
    top: 15px;
    right: 20px;
  }
  .wrapper h2 {
    font-size: 22px;
  }
  .btn-field button{
    font-size: 18px;
  }
  input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccb9cc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}
  
}
