.navbar-component{
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--mainColor);
    opacity: 0.95;
    width: 100%;
    z-index: 2;
}

.nav-logo {
    height: 50px;
    align-self: center;
    cursor: pointer;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    color: var(--textColor);
}

nav a{
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;
}

nav a:hover{
    color: var(--secondaryColor);
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

nav .nav-btn-nous-contacter {
    border: 2px solid var(--whiteColor);
    font-size: 1rem;
    padding: 10px;
    border-radius: 20px;
    background-color: transparent;
    color: var(--textColor);
    margin-left: 10px;
    margin-right: 30px;

}

nav .nav-btn-nous-contacter:hover {
    background-color: var(--whiteColor);
    color: var(--mainColor);
    padding: 10px;
    border-radius: 20px;
    transition: background-color 0.2s, color 0.2s;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 30px;
}

.nav-text-displayed {
    font-size: 1rem;
}


.nav-container{
    border-radius: 20px;
    padding: 10px;
    transition: background-color 0.2s, color 0.2s;
}

nav .nav-container:hover{
    background-color: var(--whiteColor);
    color: var(--mainColor);
}

.nav-btn-drapeau {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    margin-left: 10px;
    color: var(--whiteColor);
    font-size: 1.2rem;
}


@media only screen and (max-width: 1024px) {
    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-100vh);
    }

    header .responsive-nav{
        transform: none;
    }

    nav .nav-close-btn{
        position: absolute;
        margin-left: 0px;
        margin-right: 0px;
        top: 2rem;
        right: 2rem;
    }

    nav a{
        font-size: 1.2rem;
    }

    nav .nav-btn-nous-contacter {
        margin-left: 0px;
        margin-right: 0px;
    }

    nav .nav-btn-nous-contacter:hover {
        margin-left: 0px;
        margin-right: 0px;
    }

    nav .nav-btn-drapeau {
        margin-left: 0px;
        margin-right: 0px;
    }

    nav .nav-btn-drapeau:hover {
        margin-left: 0px;
        margin-right: 0px;
    }

    


}