  .domaine-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .buttons-container{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;

          .btn-bottom-nous-contacter {
              margin: 2% 10%;
              border: 2px solid var(--whiteColor);
              font-size: 1rem;
              padding: 10px;
              border-radius: 20px;
              background-color: var(--whiteColor);
              color: black;
          }
          
          .btn-bottom-nous-contacter:hover {
              border: 2px solid #3cacb6;
              background-color: transparent;
              color:  #3cacb6;
              padding: 10px;
              border-radius: 20px;
              transition: background-color 0.2s, color 0.2s;
              cursor: pointer;
          }
      
          .btn-bottom-top {
              margin: 2% 10%;
              border: 2px solid var(--whiteColor);
              font-size: 1rem;
              padding: 10px;
              border-radius: 20px;
              background-color: transparent;
              color: var(--textColor);
          }
          
          .btn-bottom-top:hover {
              border: 2px solid #3cacb6;
              background-color: transparent;
              color: #3cacb6;
              padding: 10px;
              border-radius: 20px;
              transition: background-color 0.2s, color 0.2s;
              cursor: pointer;
          }

      }
  }