.body {
  overflow-x: hidden;
  overflow-y: scroll;
}

.carousel {
  margin-left: auto;
  margin-right: 0;
}

.qsn {  
  font-size: 1.5rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: beige;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
  letter-spacing: 0.1em;
  line-height: 1.8em;
  width: 80%;
  margin: auto;
  overflow-y: hidden;
  text-align: center;
}

.imgmandat {
  width: 100%;
  height: 75vh;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);
}

.carousel .slide .legend {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.2rem !important;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  background:  rgba(0, 0, 0, 0.5) !important;
  opacity: 1 !important;
}

.titre {
  margin-top: 15vh;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2%;
}

.noussommes {
  font-size: 2.2rem;
  text-align: center;
}


.noussommes::after{ 
  content: '';
  display: block;
  height: 4px;
  width: 8%;
  margin: 2% auto;
  background-color: beige;
}

.soustitre {
  margin: 5% auto;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.text {
  font-size: 1.2rem;
  letter-spacing: 0.07em;
  line-height: 3em;
  font-weight: 400;
  text-align: justify;
}

.gridvaleurs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5em;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.dynamism,
.disponibility,
.quality,
.following {
  grid-template-rows: repeat(3, 1fr);
  row-gap: 0.5em;
  display: grid;
}

.dynamism {
  grid-column: 1;
}

.disponibility {
  grid-column: 2;
}

.quality {
  grid-column: 3;
}

.following {
  grid-column: 4;
}

.value {
  grid-row: 1;
}

.icone {
  grid-row: 2;
}

.textvaleurs {
  grid-row: 3;
  font-size: small;
  letter-spacing: 0.09em;
  font-weight: 500;
  line-height: 1.8em;
}

.gridpartenaires {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 1.5em;
  row-gap: 1.5em;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.partenaire {
  max-width: 80%;
  max-height: 60%;
  object-fit: contain;
  object-position: center;
  margin: auto;
  filter: grayscale(100%);
  transition: transform 0.5s ease, filter 0.5s ease;
}

.partenaire:hover {
  filter: grayscale(0%);
  transform: scale(1.3);
}

.insa {
  grid-column: 1;
  grid-row: 1;
}

.cnje {
  grid-column: 2;
  grid-row: 1;
}

.jinsa {
  grid-column: 3;
  grid-row: 1;
}

.migso {
  grid-column: 1;
  grid-row: 2;
}

.bpgo {
  grid-column: 2;
  grid-row: 2;
}

.epnak {
  grid-column: 3;
  grid-row: 2;
}

@media (max-width: 1024px) {

  .qsn {
    width: 85%;
  }

  .imgmandat {
    height: 30vh;
    object-fit: cover;
  }

  .text {
    font-size: 400;
    letter-spacing: 0.04em;
    font-weight: 400;
    margin-bottom: 3%;
    margin: auto 0% 3% 0%;
    line-height: 2.2em;
  }

  .gridvaleurs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .dynamism {
    grid-column: 1;
    grid-row: 1;
  }
  
  .disponibility {
    grid-column: 2;
    grid-row: 1;
  }
  
  .quality {
    grid-column: 1;
    grid-row: 2;
  }
  
  .following {
    grid-column: 2;
    grid-row: 2;
  }

  .gridpartenaires {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .partenaire{
    filter: grayscale(20%);
  }

  .insa {
    grid-column: 1;
    grid-row: 1;
  }

  .cnje {
    grid-column: 2;
    grid-row: 1;
  }

  .jinsa {
    grid-column: 1;
    grid-row: 2;
  }

  .migso {
    grid-column: 2;
    grid-row: 2;
  }

  .bpgo {
    grid-column: 1;
    grid-row: 3;
  }

  .epnak {
    grid-column: 2;
    grid-row: 3;
  }
}

@media (max-width: 767px) {

  .titre {
    font-size: 1.8rem;
  }

  .noussommes {
    font-size: 1.6rem;
  }

  .soustitre {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
    line-height: 2em;
    letter-spacing: 0.07em;
  }

  .gridvaleurs {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  .value {
    font-size: 1.4rem;
  }

  .dynamism {
    grid-column: 1;
    grid-row: 1;
  }
  
  .disponibility {
    grid-column: 1;
    grid-row: 2;
  }
  
  .quality {
    grid-column: 1;
    grid-row: 3;
  }
  
  .following {
    grid-column: 1;
    grid-row: 4;
  }

  .textvaleurs {
    padding-bottom: 4%;
    margin-bottom: 4%;
  }

  .gridpartenaires {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }

  .partenaire {
    filter: grayscale(20%);
    grid-column: 1;
  }
  .insa {
    grid-row: 1;
  }

  .cnje {
    grid-row: 2;
  }

  .jinsa {
    grid-row: 3;
  }

  .migso {
    grid-row: 4;
  }

  .bpgo {
    grid-row: 5;
  }

  .epnak {
    grid-row: 6;
  }
}

