.initial{
    background-image: url("../../images/Logo_fond_blanc.png");
    background-repeat: no-repeat;
    background-size: 45%;
    background-position-y: 75%;
    background-position-x: center;

}
.main-container{
    height: 100%;
    width: 100%;



    overflow-y: scroll;

    .top-container {
        margin: 0;
        height: 100vh;
        transition: transform 0.1s ease-in-out;

        .gma{
            background: linear-gradient(90deg, #7C0015 0%, #6A0723 100%);
        }
        .gcu{
            background: linear-gradient(90deg, #6A0723 0%, #4F0F23 100%);
        }
        .telecom{
            background: linear-gradient(90deg, #4F0F23 0%, #2C1623 100%);
        }
        .iot{
            background: linear-gradient(180deg, #2C1623 0%, #1A1628 100%);
        }
        .devlog{
            background: linear-gradient(90deg, #2C1623 0%, #4F0F23 100%);
        }
        .devweb{
            background: linear-gradient(90deg, #4F0F23 0%, #6A0723 100%);
        }
        .data{
            background: linear-gradient(90deg, #6A0723 0%, #7C0015 100%);
        }

        .homepage {
            margin-top: 100px;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;
            margin: 0;
    
            .container {
                display: flex;
                flex-direction: row;
    
                .colonne {
                    height: 100%;
                    width: 15%;
                    text-align: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;

                    .presta-button{
                        padding-top: 70vh;
                    }
                }
    
                .presta {
                    width: 100%;

                    .presta-name{
                        position: absolute;
                        color: beige;
                        font-family: Montserrat;
                        font-size: 2.5vw;
                    }
                }
    
                .bouton {
                    margin: 60px;
                    border: 2px solid white;
                    border-radius: 50%;
                    padding: 10px;
                    background-color: transparent;
                    color: var(--textColor);

                    .suivant{
                        width: 30px;
                        margin: 3px 3px 0px 3px;
                    }

                    .precedent{
                        width: 30px;
                        margin: 3px 3px 0px 3px;
                    }

                }
                
                .bouton:hover {
                    border: 2px solid var(--secondaryColor);
                    color: var(--secondaryColor);
                    background-color: transparent;
                    padding: 10px;
                    transition: background-color 0.2s, color 0.2s;
                    cursor: pointer;
                }


            }
        }
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
*{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media  (max-width: 768px) {
    .main-container{
        .top-container {
            .homepage {
                .container {
                    margin-top: 40%;
                    .bouton {
                        margin-top: 50%;
                    }
                    .colonne {
                        margin-top: auto;
                        .presta-button{
                            padding-top: 50vh;
                        }
                    }
                    .presta-name{
                        font-size: 5vw !important;
                        position: absolute;
                    }
                }
            }
        }
    }
}
