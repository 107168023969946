.animated-arrow-containerplus,
.animated-arrow-container {
  font-size: 3em;
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  margin-block: 40px;
  cursor: pointer;
  animation: arrowAnimation 2s infinite alternate;
}

.animated-arrow-container:hover {
  border: 2px solid var(--secondaryColor);
  color: var(--secondaryColor);
}

@keyframes arrowAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
}

@media (max-width: 768px) {
  .animated-arrow-container {
    display: none;
  }
}
