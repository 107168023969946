.page-footer-container{
    width: 100vw;
    background-color: var(--mainColor);
    flex-direction: row;
    display: flex;
    align-items: center;
    color: white;
    letter-spacing: 1px;
    /* line spacing */
    line-height: 2;
    opacity: 0.95;
    padding: 30px;

    .left-left-container{
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        
        .nav-logo {
            height: 80px;
            align-self: center;
            cursor: pointer;
        }
        
    }

    .left-container{
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .middle-container{
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .right-container{
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        
    }

    .social-container{
        width: 100%;
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .socialpng {
        height: 30px;
        width: 30px;
        margin: 10px;
        cursor: pointer;
    }
}


@media (max-width: 1024px) {
    .page-footer-container {
        line-height: 2;
        letter-spacing: 1px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        align-items: center !important;
        justify-items: center !important;
    }
    .left-left-container {
        grid-column: 1;
        grid-row: 1;
    }
    .left-container {
        grid-column: 2;
        grid-row: 1;
    }
    .middle-container {
        grid-column: 1;
        grid-row: 2;
    }
    .right-container {
        grid-column: 2;
        grid-row: 2;
    }
  }

@media (max-width: 768px) {
    .page-footer-container {
        display: flex;
    }

    .middle-container {
        display: none !important;
    }

    .left-container {
        display: none !important;
    }

  }