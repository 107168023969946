.hot-air-balloon {
  position: absolute;
  width: 5%;
  animation: deplacementGaucheDroite 120s linear infinite;
  opacity: 0.7;
}

.populated-planet {
  position: absolute;
  width: 80%;
  transform: translate(-40%, 20%);
}

.redText {
  color: var(--thirdColor);
}

.astronaut {
  animation: deplacementAstronaut 60s linear infinite;
}

.saturn-planet {
  position: absolute;
  width: 24%;
  top: 20%;
  right: 5%;
  opacity: 0.8;
}

.mars-planet {
  position: absolute;
  width: 30%;
  top: 60%;
  left: -10%;
  rotate: 45deg;
  opacity: 0.8;
}

.specialites-container,
.specialites-container-animations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 20vw;
}

.specialites-container .mecanique,
.specialites-container-animations .mecanique,
.specialites-container .electronique,
.specialites-container-animations .electronique {
  margin-right: 10vw;
}

.specialites-container .mecanique,
.specialites-container-animations .mecanique,
.specialites-container .informatique,
.specialites-container-animations .informatique {
  margin-bottom: 15vh;
}

.specialites-container-animations .mecanique {
  width: 15%;
  margin-bottom: -15vh;
  margin-right: 50vw;
}

.specialites-container .informatique,
.specialites-container-animations .informatique {
  margin-left: 10vw;
}

.specialites-container-animations .informatique {
  width: 15%;
  margin-left: 50vw;
}

.specialites-container-animations .electronique {
  width: 30%;
  margin-top: -10vh;
  margin-right: 50vw;
}

.plus-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 40vw;
}

.plus-button .text {
  margin-bottom: -20px;
  -webkit-text-fill-color: white;
}

.blur-text {
  background-color: rgba(255, 255, 255, 0.117);
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  color: var(--mainColor);
}

.page-5-pg-1,
.page-5-pg-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vh;
  padding-right: 30vw;
}

.page-5-pg-2,
.page-5-pg-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35vh;
  padding-left: 30vw;
}

.page-5-pg-3 {
  margin-top: 55vh;
}

.page-5-pg-4 {
  margin-top: 75vh;
}

.insa-fonce {
  width: 100%;
  height: auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  flex-direction: column;
  z-index: 500;
  opacity: 1;
}

@keyframes deplacementGaucheDroite {
  0% {
    transform: translate3d(-10vw, 10vh, 0);
  }
  40% {
    transform: translate3d(30vw, -30vh, 0);
  }
  100% {
    transform: translate3d(100vw, -10vh, 0);
  }
}

@keyframes deplacementAstronaut {
  0% {
    transform: translate3d(10vw, 10vh, 0);
  }
  40% {
    transform: translate3d(-30vw, -30vh, 0);
  }
  100% {
    transform: translate3d(-100vw, -60vh, 0);
  }
}

@media  (max-width: 1024px) {

  .astronaut {
    margin-top: 10vh;
  }

  .populated-planet {
    width: 100%;
    margin-top: 40vh;
  }

  .hot-air-balloon {
    width: 10%;
  }
  .saturn-planet {
    width: 40%;
  }
  .mars-planet {
    width: 50%;
  }

  .sattelite {
    width: 150%;
  }

  .astronaut {
    width: 200%;
  }

  .specialites-container-animations {
    margin-left: -10vw;
  }

  .specialites-container-animations .informatique {
    margin-right: 50vw;
    margin-left: 0;
    margin-bottom: 10vh;
  }

  .specialites-container-animations .mecanique {
    margin-bottom: 10vh;
  }
  
  .specialites-container-animations .electronique {
    margin-top: 0;
  }

  .plus-button {
    margin-right: 0;
    .text {
      margin: 0 0 -30px 0;
    }
  }
  .page-5-pg-1,
  .page-5-pg-3 {
    padding-right: 0;
  }
  .page-5-pg-2,
  .page-5-pg-4 {
    padding-left: 0;
  }

  .footer {
    height: auto;
    
  }

  .insa-fonce {
    width: 100%;
    object-fit: cover;
  }

@media  (max-width: 768px) {
  .saturn-planet {
    width: 60%;
  }
  .mars-planet {
    width: 70%;
  }
  .hot-air-balloon {
    width: 25%;
  }

  .specialites-container {
    margin-left: 5vw;
  }
  .plus-button {
      justify-content: end;
      margin-left: 0;
  }

  .page-5-pg-1 {
    margin-top: 10vh;
  }
  .page-5-pg-2 {
    margin-top: 35vh;
  }
  .page-5-pg-3 {
    margin-top: 65vh;
  }
  .page-5-pg-4 {
    margin-top: 100vh;
  }
}
}