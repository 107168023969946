.btn-nous-contacter {
  border: 5px solid var(--whiteColor);
  font-size: 3rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 20px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  color: var(--whiteColor);
  margin-left: 10px;
  margin-right: 30px;
}

.btn-nous-contacter:hover {
  color: var(--thirdColor);
  border: 5px solid var(--thirdColor);
  border-radius: 20px;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 30px;
}

@media (max-width: 1024px) {
  .btn-nous-contacter {
    font-size: 2.5rem;
  }
  .btn-nous-contacter:hover {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .btn-nous-contacter {
    font-size: 2rem;
  }
  .btn-nous-contacter:hover {
    font-size: 2rem;
  }
}
