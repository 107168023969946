@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
body {
    background-color: 323044;
    background: linear-gradient(180deg, #191919 80%, rgba(0, 0, 0, 0.914) 95%,rgba(0, 0, 0, 0.826)100%);
    margin: 0;
    padding: 0;
}


.textdesc {
    color: beige;
    text-align: justify;
    margin: 3% 10%;
    line-height: 2em;
    font-size: 1.2rem;
    line-height: 3em;
    font-weight: 400;
}

.title {
    color: beige;
    font-family: Montserrat;
    font-size:x-large;
    letter-spacing: 0.1em;
    line-height: 2em;
    margin: auto 0;
    text-align:center;
    font-weight: 800;
}

.MuiTab-root:focus {
    outline: none;
    box-shadow: none;
  }
  
.tabpanel {
    border: 1px solid #ccc;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    border-color: #3cacb6;
    border-radius: 50px;
    border-width: 0.15em;
}

@media (max-width: 767px) {
    .textdesc {
        margin-left: 5%;
        margin: 0% 5%;
        padding: 10% 0;
        font-size: medium;
        line-height: 2.0em;
    }
    .title {
        margin-left: 5%;
        margin-right: 5%;
    }
    .tabpanel {
        margin-left: 1%;
        margin-right: 1%;
    }
}
